import { Timestamp } from '@chapo/shared-utils';
import { UserDetails, UserRole } from '@chapo/users-data';

export interface AuthCredentials {
  email: string;
  password: string;
}

export interface AuthState {
  jwt: string | null;
  user: {
    id: number;
    name: string;
    role: UserRole;
    created_at: Timestamp;
    updated_at: Timestamp;
    email: string;
    companies: UserDetails['companies'];
    permissions: UserDetails['permissions'];
  } | null;
}

export function createInitialState(): AuthState {
  return {
    jwt: localStorage.getItem('jwt') ?? null,
    user: null,
  };
}
