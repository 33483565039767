import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@chapo/shared-i18n';
import { CRUD } from '@chapo/shared-utils';
import { Feature, UserDetails } from '@chapo/users-data';
import { filterSuccessResult, injectMutation, injectQuery, toPromise } from '@ngneat/query';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { exhaustMap, map } from 'rxjs';
import { AuthCredentials, AuthState, createInitialState } from '../data-access/auth-state';
import { LoginResponse, LogoutResponse } from '../data-access/login-response.type';
import { ResetPasswordForm } from '../data-access/reset-password-type';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ComponentStore<AuthState> {
  private router = inject(Router);
  private http = inject(HttpClient);
  private query = injectQuery();
  private mutation = injectMutation();
  private messageService = inject(NzMessageService);
  private modalService = inject(NzModalService);
  private i18nSevice = inject(I18nService);
  isAuthenticated$ = this.select((state) => !!state.jwt);
  currentUser$ = this.getCurrentUser().result$.pipe(filterSuccessResult());
  hasAdminRole$ = this.currentUser$.pipe(
    map((user) => user.data.role === 'admin' || user.data.role === 'platform_admin'),
  );
  belongsToAdminCompany$ = this.currentUser$.pipe(map((user) => user.data.companies.some((company) => company.admin)));

  constructor() {
    super(createInitialState());
  }

  login = this.effect<{ credentials: AuthCredentials; returnUrl?: string }>((source$) => {
    return source$.pipe(
      exhaustMap(({ credentials, returnUrl }) => {
        return this.http
          .post<LoginResponse>(
            `/backend/login`,
            { user: { email: credentials.email, password: credentials.password } },
            { observe: 'response' },
          )
          .pipe(
            tapResponse({
              next: (response) => {
                const data = response.body!.data;
                const jwt = response.headers.get('Authorization')?.replace('Bearer ', '') ?? null;

                localStorage.setItem('jwt', jwt ?? '');

                this.patchState({
                  jwt,
                  user: {
                    id: data.id,
                    name: data.name,
                    role: data.role,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    email: data.email,
                    companies: data.companies,
                    permissions: data.permissions,
                  },
                });

                this.messageService.success(this.i18nSevice.translate('auth.login.response.success'));
                this.router.navigateByUrl(returnUrl ?? '/');
              },
              error: () => {
                this.messageService.error(this.i18nSevice.translate('auth.login.response.error'));
                this.resetState();
              },
            }),
          );
      }),
    );
  });

  logout = this.effect<void>((source$) => {
    return source$.pipe(
      exhaustMap(() => {
        return this.http.delete<LogoutResponse>(`/backend/logout`, {}).pipe(
          tapResponse({
            next: () => {
              this.resetState();
              this.modalService.closeAll();
              this.messageService.error(this.i18nSevice.translate('auth.logout.response.success'));

              this.router.navigate(['/auth/login'], {
                queryParams: { returnUrl: undefined },
              });
            },
            error: () => {},
          }),
        );
      }),
    );
  });

  logoutFromInterceptor = this.effect<void>((source$) => {
    return source$.pipe(
      exhaustMap(() => {
        return this.http.delete<LogoutResponse>(`/backend/logout`, {}).pipe(
          tapResponse({
            next: () => {
              this.resetState();
              this.modalService.closeAll();
              this.messageService.error(this.i18nSevice.translate('auth.logout.response.success'));

              const requestedUrl = this.router.url;
              const returnUrl = requestedUrl === '/' ? undefined : requestedUrl;

              this.router.navigate(['/auth/login'], {
                queryParams: { returnUrl },
              });
            },
            error: () => {},
          }),
        );
      }),
    );
  });

  hasPermission(feature: Feature, permissionType: keyof CRUD) {
    return this.currentUser$.pipe(map((user) => user.data.permissions[feature][permissionType]));
  }

  confirmInvitation() {
    return this.mutation({
      mutationFn: ({ model, invitation_token }: { model: ResetPasswordForm; invitation_token: string }) => {
        return this.http.put(`/backend/invitation`, model, { params: { invitation_token } });
      },
    });
  }

  resetPassword() {
    return this.mutation({
      mutationFn: ({ model }: { model: { user: { email: string } } }) => {
        return this.http.post(`/backend/password`, model);
      },
    });
  }

  confirmResetPassword() {
    return this.mutation({
      mutationFn: ({ model, reset_password_token }: { model: ResetPasswordForm; reset_password_token: string }) => {
        return this.http.put(`/backend/password`, { user: { ...model, reset_password_token } });
      },
    });
  }

  private getCurrentUser() {
    return this.query({
      queryKey: ['users', 'me'] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<UserDetails>(`/backend/users/me`).pipe(
          tapResponse({
            next: (data) => {
              this.patchState({
                user: {
                  id: data.id,
                  name: data.name,
                  role: data.role,
                  created_at: data.created_at,
                  updated_at: data.updated_at,
                  email: data.email,
                  companies: data.companies,
                  permissions: data.permissions,
                },
              });
            },
            error: (response) => {
              console.log(response);
            },
          }),
        );

        return toPromise({ source, signal });
      },
      retry: false,
    });
  }

  private resetState() {
    localStorage.removeItem('jwt');
    this.setState(createInitialState());
  }
}
